import { Pipe, PipeTransform } from '@angular/core';
import { LoadOptionDto, LoadOptionsEnum, SelectOptionDto } from '../../core/services/api.service';

@Pipe({
    name: 'loadoptions',
    standalone: true
})
export class LoadOptionsPipe implements PipeTransform {

  transform(value: LoadOptionsEnum, options: SelectOptionDto[] | LoadOptionDto[]): string {
    let selected: SelectOptionDto[] = [];

    options.forEach(x => {
      let optionValue = Number(x.value);

      if ((optionValue & value) != 0 && x.label) {
        var option = new SelectOptionDto();
        option.label = x.label;
        option.value = x.value;

        selected.push(option);
      }
    });

    return selected
      .sort((a,b) => a.label! > b.label! ? 1 : -1)
      .map(x => `<div class='load-option load-${x.value}'>${x.label}</div>`)
      .join("");
  }
}
